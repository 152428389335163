import get from 'lodash/get';
import { API } from '../../api';
import {
  FETCH_DOCUMENT,
  DELETE_CUSTOMER_DOCUMENT,
  UPLOAD_DOCUMENT,
  SAVE_CUSTOMER_DOCUMENT,
  UPDATE_CUSTOMER_DOCUMENT,
  FETCH_DOCUMENT_TYPES,
  RESET_PAGE_CLIENT_DOCUMENTS,
  PATCH_DOCUMENT_TYPES
} from './actionTypes';

import * as documentActions from '../reducers/clientDocuments.reducer'

export function resetPageClientDocuments(data) {
  return { type: RESET_PAGE_CLIENT_DOCUMENTS };
}

export function loadDocuments(data) {
  return { type: FETCH_DOCUMENT, data };
}
export function loadDocumentTypes(data) {
  return { type: FETCH_DOCUMENT_TYPES, data };
}

export function uploadDocumentAction(data) {
  return { type: UPLOAD_DOCUMENT, data };
}
export function saveCustomerDocumentAction(data) {
  return { type: SAVE_CUSTOMER_DOCUMENT, data };
}
export function updateDocumentAction(data) {
  return { type: UPDATE_CUSTOMER_DOCUMENT, data };
}
export function deleteDocumentAction(data) {
  return { type: DELETE_CUSTOMER_DOCUMENT, data };
}

export function patchDocumentTypes(data) {
  return { type: PATCH_DOCUMENT_TYPES, data };
}

export const uploadDocument = async (clientId, params, documentType = '') => {
  try {
    let uploadUrl = 'document/upload';
    let needAuth = false;
    if (clientId && clientId !== '') {
      uploadUrl = 'document/customer/' + clientId + `?documentType=${documentType}`;
      needAuth = true;
    }
    const res = await API.formData(uploadUrl, params, needAuth);
    const { result, isSuccess, errors } = res;
    return result
  } catch (error) {
    const { message, isError } = error;
    return await Promise.reject(error);
  }

};

export const fetchDocuments = ({ customerId }) => {
  return async (dispatch) => {
    try {
      dispatch(documentActions.setLoading(true));
      const res = await API.get(`customers/${customerId}/documents/`, true);
      const { result, isSuccess, errors } = res;
      dispatch(documentActions.loadDocuments(result));
      dispatch(documentActions.setLoading(false));

    } catch (error) {
      const { message, isError } = error;
      dispatch(documentActions.setLoading(false));
      return await Promise.reject(error);
    }
  };
};

export const fetchSumsubDocuments = async ({ customerId }) => {
    try {
      const res = await API.get(`customers/${customerId}/sumsub-documents/`, true);
      const { result, isSuccess, errors } = res;
      return result;

    } catch (error) {
      const { message, isError } = error;
      return await Promise.reject(error);
    }
};
export const getCustomerSumbubApplicant = async ({ customerId }) => {
  try {
    const res = await API.get(`customers/${customerId}/check-sumsub-applicant/`, true);
    const { result, isSuccess, errors } = res;
    return result;

  } catch (error) {
    const { message, isError } = error;
    return await Promise.reject(error);
  }
};


export const getApplicationLink = async (customerId, type) => {
  try {
    const res = await API.get(`customers/${customerId}/sumsub-application-link/?type=${type}`, true);
    const { result, isSuccess, errors } = res;
    return result;

  } catch (error) {
    const { message, isError } = error;
    return await Promise.reject(error);
  }
};
export const getReportLink = async (customerId)=>{
  try {
    const res = await API.get(`customers/${customerId}/summary/report`, true);
    const { result, isSuccess, errors } = res;
    return result;

  } catch (error) {
    const { message, isError } = error;
    return await Promise.reject(error);
  }
}
export const getDocumentUrl = (url) => {
  return API.get(`document/download?link=${url}`, true);
};

export const getSumSubDoc = (customerId, appId, imageId) => {
  return API.get(`customers/${customerId}/sumsub-documents/${appId}/${imageId}`, true);
};
export const getSumSubData = (customerId)=>{
  return API.get(`customers/${customerId}/sumsub-details/`, true);
}

export const getFile = (id) => {
  return API.getNoParse(`document/download/${id}`, true);
}

export const saveCustomerDocument = (clientId, document) => {
  return async (dispatch, getState) => {
    try {
      dispatch(documentActions.setUploadLoading(true))
      dispatch(documentActions.setLoading(true))

      let filesList = [];
      for (let file of document.files) {
        let formData = new FormData();
        formData.append('file', file.data);
        // const res = await axios.post('/my/upload-document', formData);
        const blobResult = await uploadDocument(
          clientId,
          formData,
          document.title
        )
        const { url } = blobResult;
        const updatedFile = {
          path: url,
          name: file.name
        };
        if (file.type)
          updatedFile.type = file.type;
        filesList.push(updatedFile);
      }
      document.files = filesList;
      document.customerId = clientId;
      const res = await API.post('customers/documents/add', document, true);
      const { result, isSuccess } = res;
      dispatch(documentActions.setUploadLoading(false))
      dispatch(documentActions.addDocument(result))
      dispatch(documentActions.setLoading(false))


      return res;
    } catch (error) {
      dispatch(documentActions.setUploadLoading(false))
      dispatch(documentActions.setLoading(false))
      return error
    }
  };
};

export const updateDocument = (customerId, documentId, status, reason) => {
  return async (dispatch) => {
    try {
      dispatch(documentActions.setLoading(true));
      const dataToUpdate = {
        status
      }
      if (reason)
        Object.assign(dataToUpdate, reason);
      const res = await API.patch(
        `customers/${customerId}/documents/${documentId}`,
        dataToUpdate,
        true
      );
      const { result, isSuccess, errors } = res;
      dispatch(documentActions.updateDocument({ documentId: result._id, newDocument: result }));
      dispatch(documentActions.setLoading(false))
    } catch (error) {
      const { message, isError } = error;
      dispatch(documentActions.setLoading(false));

      return await Promise.reject(error);
    }
  };
};

export const deleteDocument = (customerId, documentId, pramas = {}) => {
  return async (dispatch) => {
    try {
      dispatch(documentActions.setLoading(true));
      const res = await API.delete(
        `customers/${customerId}/documents/${documentId}`,
        {},
        true
      );
      dispatch(documentActions.deleteDocument(documentId));
      dispatch(documentActions.setLoading(false));

    } catch (error) {
      const { message, isError } = error;
      dispatch(documentActions.setLoading(false));
      return await Promise.reject(error);
    }
  };
};
export const addDocumentFile = (customerId, documentId, fileId, params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(updateDocumentAction({ isLoading: true }));
      const res = await API.patch(
        `customers/${customerId}/documents/${documentId}`,
        params,
        true
      );
      const { result, isSuccess, errors } = res;
      dispatch(
        updateDocumentAction({
          document: { ...result, staus: params.status, index: params.index },
          isLoading: false,
          isSuccess
        })
      );
    } catch (error) {
      const { message, isError } = error;
      dispatch(updateDocumentAction({ isError, isLoading: false }));
      return await Promise.reject(error);
    }
  };
};
export const updateDocumentFile = (customerId, fileId, status, reason) => {
  return async (dispatch) => {
    try {
      dispatch(documentActions.setUpdateLoading(true));


      const dataToUpdate = {
        status
      }
      if (reason)
        Object.assign(dataToUpdate, reason);
      const res = await API.patch(
        `customers/${customerId}/documents/file/${fileId}`,
        dataToUpdate,
        true
      );
      const { result, isSuccess, errors } = res;
      dispatch(documentActions.updateDocument({ documentId: result._id, newDocument: result }));
      dispatch(documentActions.setUpdateLoading(false));
    } catch (error) {
      const { message, isError } = error;
      dispatch(documentActions.setUpdateLoading(false));
      return await Promise.reject(error);
    }
  };
};
export const deleteDocumentFile = (customerId, fileId, params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(documentActions.setUpdateLoading(true));
      const res = await API.delete(
        `customers/${customerId}/documents/file/${fileId}`,
        params,
        true
      );
      const { result, isSuccess, errors } = res;
      dispatch(documentActions.updateDocument({ documentId: result._id, newDocument: result }));
      dispatch(documentActions.setUpdateLoading(false));

    } catch (error) {
      const { message, isError } = error;
      dispatch(documentActions.setUpdateLoading(false));

      return await Promise.reject(error);
    }
  };
};
export const fetchDocumentTitles = (pramas = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(loadDocumentTypes({ isLoading: true }));
      const res = await API.get(
        'configuration/settings?data=kycRequirements',
        false
      );
      const { result, isSuccess, isError } = res;
      dispatch(
        loadDocumentTypes({ documentTitles: result, isLoading: false, isError, documentTitlesOg: result })
      );
    } catch (error) {
      const { message, isError } = error;
      dispatch(loadDocumentTypes({ isError, isLoading: false }));
      return await Promise.reject(error);
    }
  };
};


