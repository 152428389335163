import {
  GET_REPORTING_ALL,
  GET_REPORTING_LOADING,
  GET_REPORTING_ALL_ERROR,
} from '../actions/actionTypes';

const initialState = {
  reportingData: {
    docs: [],
    count: 0,
    page: 1,
    totalPages: 0
  },
  reportingLoading: false,
  errorStatus: false,
  error: '',
}

const reportingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTING_ALL:
      return {
        ...state,
        reportingData: { ...state.reportingData, ...action.payload },
        reportingLoading: false,
      };
    case GET_REPORTING_LOADING:
      return {
        ...state,
        reportingLoading: !state.reportingLoading
      };
    case GET_REPORTING_ALL_ERROR:
      return {
        ...state,
        reportingLoading: false,
        errorStatus: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reportingReducer;
