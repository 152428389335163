import { get } from 'lodash';
import {
  LIST_LIVE_ACCOUNTS,
  RESET_ACCOUNTS_PASSWORD,
  CHANGE_ACCOUNTS_STATUS,
  CREATE_MT5_ACCOUNT,
  LINK_MT5_ACCOUNT,
  LINK_CAMPAIGN_ACCOUNT,
  CHANGE_MT5_ACCOUNT_GROUP,
  ACCOUNT_MT5_OPEN_POSITIONS,
  ACCOUNT_MT5_OPEN_ORDERS,
  ACCOUNT_MT5_CLOSE_POSITIONS,
  RESET_POSITIONS,
  LINK_STRUCTURE,
  UNLINK_STRUCTURE,
  RESET_PAGE_CLIENT_ACCOUNTS
} from '../actions/actionTypes';
import { replaceArrayItemsWithAnotherArrayById } from '../../utils';

const initialState = {
  accounts: [],
  isLoading: false,
  page: 1,
  totalPages: 1,
  actionLoading: false
};

export const clientAccountsSelector = (state) =>
  get(state, 'clientAccounts', []);
const clientAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_MT5_ACCOUNT:
      return { ...state, ...action.data };
    case LINK_MT5_ACCOUNT:
      return { ...state, ...action.data };
    case LINK_CAMPAIGN_ACCOUNT:
      return { ...state, ...action.data };
    case LIST_LIVE_ACCOUNTS:
      return { ...state, ...action.data };
    case RESET_ACCOUNTS_PASSWORD:
    case CHANGE_ACCOUNTS_STATUS:
      return { ...state, ...action.data };
    case ACCOUNT_MT5_OPEN_POSITIONS:
      return { ...state, ...action.data };
    case ACCOUNT_MT5_OPEN_ORDERS:
      return { ...state, ...action.data };
    case ACCOUNT_MT5_CLOSE_POSITIONS:
      return { ...state, ...action.data };
    case RESET_POSITIONS:
      return { ...state, ...action.data };
    case CHANGE_MT5_ACCOUNT_GROUP:
      return updateAccountType(state, action);
    case LINK_STRUCTURE:
      return updateAccountType(state, action);
    case UNLINK_STRUCTURE:
      return updateAccountStructure(state, action.data);
    case RESET_PAGE_CLIENT_ACCOUNTS:
      return { ...initialState };
    default:
      return state;
  }
};

const updateAccountType = (state, action) => {
  if (action.data.isUpdate) {
    const { updatedApplication, isUpdate, ...rest } = action.data;
    let accounts = replaceArrayItemsWithAnotherArrayById(state.accounts, [
      updatedApplication
    ]);
    return {
      ...state,
      accounts,
      ...rest
    };
  } else {
    return {
      ...state,
      ...action.data
    };
  }
};

const updateAccountStructure = (state, updatedApplication) => {
  console.log('-----', state, updatedApplication)
  let accounts = replaceArrayItemsWithAnotherArrayById(state.accounts, [
    updatedApplication
  ]);
  return {
    ...state,
    accounts
  };
};

export default clientAccountsReducer;
