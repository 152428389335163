export const BEGIN_API_CALL = 'BEGIN_API_CALL';
export const END_API_CALL = 'END_API_CALL';
export const API_CALL_ERROR = 'API_CALL_ERROR';

/**
 * Auth's Actions
 */
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
/**
 * Campaign Actions
 */
export const LOAD_CAMPAIGNS_SUCCESS = 'LOAD_CAMPAIGNS_SUCCESS';
export const LOAD_CAMPAIGNS_ERROR = 'LOAD_CAMPAIGNS_ERROR';
export const LOAD_CAMPAIGN_SUCCESS = 'LOAD_CAMPAIGN_SUCCESS';
export const LOAD_CAMPAIGN_ERROR = 'LOAD_CAMPAIGN_ERROR';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_ERROR = 'CREATE_CAMPAIGN_ERROR';
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_ERROR = 'UPDATE_CAMPAIGN_ERROR';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_ERROR = 'DELETE_CAMPAIGN_ERROR';
export const ADD_EMPTY_FIELD = 'ADD_EMPTY_FIELD';
export const REMOVE_EMPTY_FIELD = 'REMOVE_EMPTY_FIELD';

/**
 * User's Actions
 */
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_LOADING = 'LOAD_USERS_LOADING';
export const LOAD_USERS_ERROR = 'LOAD_USERS_ERROR';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
export const USER_STATUS_UPDATE_ERROR = 'USER_STATUS_UPDATE_ERROR';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const DELETE_USER_OPTIMISTIC = 'DELETE_USER_OPTIMISTIC';
export const USER_STATUS_UPDATE_OPTIMISTIC = 'USER_STATUS_UPDATE_OPTIMISTIC';

export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export const RESET_USER_REDUCER = 'RESET_USER_REDUCER';

export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
export const CHANGE_HAS_UPDATE = 'CHANGE_HAS_UPDATE';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const USER_LOG_ACCESS = 'USER_LOG_ACCESS';


/**
 * UTM Campaign's Actions
 */
export const LOAD_PROMOTIONS_SUCCESS = 'LOAD_PROMOTIONS_SUCCESS';
export const LOAD_PROMOTIONS_ERROR = 'LOAD_PROMOTIONS_ERROR';
export const LOAD_PROMOTION_SUCCESS = 'LOAD_PROMOTION_SUCCESS';
export const LOAD_PROMOTION_ERROR = 'LOAD_PROMOTION_ERROR';
export const LOAD_ACCOUNTPROMOS_SUCCESS = 'LOAD_ACCOUNTPROMOS_SUCCESS';
export const LOAD_ACCOUNTPROMOS_ERROR = 'LOAD_ACCOUNTPROMOS_ERROR';
export const DELETE_PROMOTION_SUCCESS = 'DELETE_PROMOTION_SUCCESS';
export const DELETE_PROMOTION_ERROR = 'DELETE_PROMOTION_ERROR';
export const CREATE_PROMOTION_SUCCESS = 'CREATE_PROMOTION_SUCCESS';
export const CREATE_PROMOTION_ERROR = 'CREATE_PROMOTION_ERROR';
export const UPDATE_PROMOTION_SUCCESS = 'UPDATE_PROMOTION_SUCCESS';
export const UPDATE_PROMOTION_ERROR = 'UPDATE_PROMOTION_ERROR';

/**
 * User's Actions
 */
export const DATA_LEADS_SUCCESS = 'DATA_LEADS_SUCCESS';
export const DATA_LEADS_ERROR = 'DATA_LEADS_ERROR';
export const DATA_CLIENTS_SUCCESS = 'DATA_CLIENTS_SUCCESS';
export const DATA_CLIENTS_ERROR = 'DATA_CLIENTS_ERROR';
export const DATA_IBS_SUCCESS = 'DATA_IBS_SUCCESS';
export const DATA_IBS_ERROR = 'DATA_IBS_ERROR';
export const DATA_REQUESTS_PENDING_SUCCESS = 'DATA_REQUESTS_PENDING_SUCCESS';
export const DATA_REQUESTS_PENDING_ERROR = 'DATA_REQUESTS_PENDING_ERROR';
export const DATA_TRANSACTIONS_PENDING_SUCCESS =
  'DATA_TRANSACTIONS_PENDING_SUCCESS';
export const DATA_TRANSACTIONS_PENDING_ERROR =
  'DATA_TRANSACTIONS_PENDING_ERROR';
export const DATA_KYC_PENDING_SUCCESS = 'DATA_KYC_PENDING_SUCCESS';
export const DATA_KYC_PENDING_ERROR = 'DATA_KYC_PENDING_ERROR';
/**
 * Team's Actions
 */
export const LOAD_TEAMS_SUCCESS = 'LOAD_TEAMS_SUCCESS';
export const LOAD_TEAMS_ERROR = 'LOAD_TEAMS_ERROR';
export const LOAD_TEAM_SUCCESS = 'LOAD_TEAM_SUCCESS';
export const LOAD_TEAM_ERROR = 'LOAD_TEAM_ERROR';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_ERROR = 'DELETE_TEAM_ERROR';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_ERROR = 'CREATE_TEAM_ERROR';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_ERROR = 'UPDATE_TEAM_ERROR';
export const ADD_TEAM_MEMBER_SUCCESS = 'ADD_TEAM_MEMBER_SUCCESS';
export const ADD_TEAM_MEMBER_ERROR = 'ADD_TEAM_MEMBER_ERROR';
export const UPDATE_TEAM_MEMBER_SUCCESS = 'UPDATE_TEAM_MEMBER_SUCCESS';
export const UPDATE_TEAM_MEMBER_ERROR = 'UPDATE_TEAM_MEMBER_ERROR';
export const DELETE_TEAM_MEMBER_SUCCESS = 'DELETE_TEAM_MEMBER_SUCCESS';
export const DELETE_TEAM_MEMBER_ERROR = 'DELETE_TEAM_MEMBER_ERROR';
export const UPDATE_TEAM_MANAGER_SUCCESS = 'UPDATE_TEAM_MANAGER_SUCCESS';
export const UPDATE_TEAM_MANAGER_ERROR = 'UPDATE_TEAM_MANAGER_ERROR';
export const RESET_TEAM_REDUCER = 'RESET_TEAM_REDUCER';

/**
 * Role's Actions
 */
export const LOAD_ROLES_SUCCESS = 'LOAD_ROLES_SUCCESS';
export const LOAD_ROLES_ERROR = 'LOAD_ROLES_ERROR';
export const LOAD_ROLE_SUCCESS = 'LOAD_ROLE_SUCCESS';
export const LOAD_ROLE_ERROR = 'LOAD_ROLE_ERROR';
export const LOAD_PERMISSIONS_SUCCESS = 'LOAD_PERMISSIONS_SUCCESS';
export const LOAD_PERMISSIONS_ERROR = 'LOAD_PERMISSIONS_ERROR';
export const CREATE_ROLE_ERROR = 'CREATE_ROLE_ERROR';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';
export const RESET_ROLE_REDUCER = 'RESET_ROLE_REDUCER';

export const DELETE_ROLE_OPTIMISTIC = 'DELETE_ROLE_OPTIMISTIC';

/**
 * Dashboard ACtions
 */
export const GET_MY_PROFILE = 'GET_MY_PROFILE';
export const GET_MY_PROFILE_SUCCESS = 'GET_MY_PROFILE_SUCCESS';
export const GET_MY_PROFILE_FAILURE = 'GET_MY_PROFILE_FAILURE';
export const GET_CONFIG = 'GET_CONFIG';
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'GET_CONFIG_FAILURE';
export const GET_MAP_DATA = 'GET_MAP_DATA';
export const GET_SALES_STAT_DATA = 'GET_SALES_STAT_DATA';
export const GET_IB_STAT_DATA = 'GET_IB_STAT_DATA';
export const GET_REBCOMM_STAT_DATA = 'GET_REBCOMM_STAT_DATA';
export const GET_SALES_SUMMARY_DATA = 'GET_SALES_SUMMARY_DATA';
export const GET_SALES_SUMMARY_DATA_LOADING = 'GET_SALES_SUMMARY_DATA_LOADING';

/**
 * Email template Actions
 */
export const GET_EMAIL_TEMPLATES = 'GET_EMAIL_TEMPLATES';
export const FETCH_EMAIL_TEMPLATES = 'FETCH_EMAIL_TEMPLATES';
export const FETCH_CUSTOM_EMAIL_TEMPLATES = 'FETCH_CUSTOM_EMAIL_TEMPLATES';

export const UPDATE_EMAIL_TEMPLATE_DETAIL = 'UPDATE_EMAIL_TEMPLATE_DETAIL';
export const FETCH_EMAIL_TEMPLATE_DETAIL = 'FETCH_EMAIL_TEMPLATE_DETAIL';
export const GET_EMAIL_TEMPLATE_DETAIL = 'GET_EMAIL_TEMPLATE_DETAIL';
export const RESET_EMAIL_TEMPLATE_REDUCER = 'RESET_EMAIL_TEMPLATE_REDUCER';

/**
 * Document Actions
 */

export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const FETCH_DOCUMENT = 'FETCH_DOCUMENT';
export const FETCH_DOCUMENT_TYPES = 'FETCH_DOCUMENT_TYPES';
export const SAVE_CUSTOMER_DOCUMENT = 'SAVE_CUSTOMER_DOCUMENT';
export const DELETE_CUSTOMER_DOCUMENT = 'DELETE_CUSTOMER_DOCUMENT';
export const UPDATE_CUSTOMER_DOCUMENT = 'UPDATE_CUSTOMER_DOCUMENT';
export const PATCH_DOCUMENT_TYPES = 'PATCH_DOCUMENT_TYPES';

/**
 * Bank Details Actions
 */
export const GET_BANK_ACCOUNT = 'GET_BANK_ACCOUNT';
export const FETCH_BANK_ACCOUNTS = 'FETCH_BANK_ACCOUNTS';
export const ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT';
export const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT';
export const REMOVE_BANK_ACCOUNT = 'REMOVE_BANK_ACCOUNT';
export const RESET_BANK_ACCOUNT_REDUCER = 'RESET_BANK_ACCOUNT_REDUCER';

/**
 * Client Detail Actions
 */
export const FETCH_CLIENT_DETAIL = 'FETCH_CLIENT_DETAIL';
export const UPDATE_CLIENT_PARTNERSHIP = 'UPDATE_CLIENT_PARTNERSHIP';
export const FETCH_CLIENT_EMPLOYMENT_DETAIL = 'FETCH_CLIENT_EMPLOYMENT_DETAIL';
export const FETCH_CLIENT_DIRECTORS_DETAIL = 'FETCH_CLIENT_DIRECTORS_DETAIL';
export const FETCH_CLIENT_SHAREHOLDERS_DETAIL =
  'FETCH_CLIENT_SHAREHOLDERS_DETAIL';
export const FETCH_CLIENT_FINANCIAL_INFORMATION =
  'FETCH_CLIENT_FINANCIAL_INFORMATION';
export const FETCH_CLIENT_DECLARATIONS = 'FETCH_CLIENT_DECLARATIONS';
export const FETCH_CLIENT_CARDS = 'FETCH_CLIENT_CARDS';
export const FETCH_IB_QUESTIONAIRE = 'FETCH_IB_QUESTIONAIRE';
export const GET_CLIENT_DETAIL = 'GET_CLIENT_DETAIL';
export const FETCH_CLIENT_TOTAL_ACCOUNTS = 'FETCH_CLIENT_TOTAL_ACCOUNTS';
export const UPDATE_CLIENT_DETAIL = 'UPDATE_CLIENT_DETAIL';
export const RESET_CLIENT_PASSWORD = 'RESET_CLIENT_PASSWORD';
export const FETCH_CLIENT_STATUS = 'FETCH_CLIENT_STATUS';
export const FETCH_KYC_STATUS = 'FETCH_KYC_STATUS';
export const UPDATE_CLIENT_STATUS = 'UPDATE_CLIENT_STATUS';
export const CLIENT_ASSIGN_SALES = 'CLIENT_ASSIGN_SALES';
export const LINK_SUB_IB = 'LINK_SUB_IB';
export const UNLINK_SUB_IB = 'UNLINK_SUB_IB';
export const CLIENT_REQUEST_IB_APPOVED_STATUS = 'CLIENT_REQUEST_IB_APPOVED_STATUS';
export const CLIENT_OTHER_REQUEST_IB_APPOVED_STATUS = 'CLIENT_OTHER_REQUEST_IB_APPOVED_STATUS';
export const FETCH_SUB_IBS = 'FETCH_SUB_IBS';
export const FETCH_SOURCES = 'FETCH_SOURCES';

export const RESET_PAGE_CLIENT_PROFILE = 'RESET_PAGE_CLIENT_PROFILE';
export const RESET_PAGE_CLIENT_BANK_DETAILS = 'RESET_PAGE_CLIENT_BANK_DETAILS';
export const RESET_PAGE_CLIENT_DOCUMENTS = 'RESET_PAGE_CLIENT_DOCUMENTS';
export const RESET_PAGE_CLIENT_ACCOUNTS = 'RESET_PAGE_CLIENT_ACCOUNTS';
export const RESET_PAGE_CLIENT_ACTIVITIES = 'RESET_PAGE_CLIENT_ACTIVITIES';
export const RESET_PAGE_CLIENT_LOGS = 'RESET_PAGE_CLIENT_LOGS';
export const RESET_PAGE_CLIENT_TRANSACTIONS = 'RESET_PAGE_CLIENT_TRANSACTIONS';
export const RESET_PAGE_CLIENT_REFERRALS = 'RESET_PAGE_CLIENT_REFERRALS';
export const RESET_PAGE_CLIENT_PARTNERSHIP = 'RESET_PAGE_CLIENT_PARTNERSHIP';
export const RESET_PAGE_CLIENT_STATEMENT = 'RESET_PAGE_CLIENT_STATEMENT';

export const RESET_PAGE_CLIENTS = 'RESET_PAGE_CLIENTS';
export const RESET_PAGE_DASHBOARD = 'RESET_PAGE_DASHBOARD';
export const SET_EXTRA_DETAILS = 'SET_EXTRA_DETAILS';
export const CHANGE_IB_CLIENT_WITHDRAW = 'CHANGE_IB_CLIENT_WITHDRAW';

/**
 * Call Status
 */
export const FETCH_CALL_STATUS = 'FETCH_CALL_STATUS';
export const UPDATE_CALL_STATUS = 'UPDATE_CALL_STATUS';

/**
 * Client Activity Actions
 */
export const FETCH_CLIENT_ACTIVITIES = 'FETCH_CLIENT_ACTIVITIES';
export const FETCH_CLIENT_LOGS = 'FETCH_CLIENT_LOGS';
export const DELETE_CLIENT_ACTIVITIE = 'DELETE_CLIENT_ACTIVITIE';

/**
 * Client Accounts Action
 */
export const LIST_LIVE_ACCOUNTS = 'LIST_LIVE_ACCOUNTS';
export const RESET_ACCOUNTS_PASSWORD = 'RESET_ACCOUNTS_PASSWORD';
export const CHANGE_ACCOUNTS_STATUS = 'CHANGE_ACCOUNTS_STATUS';
export const CREATE_MT5_ACCOUNT = 'CREATE_MT5_ACCOUNT';
export const LINK_MT5_ACCOUNT = 'LINK_MT5_ACCOUNT';
export const SWAP_FREE_MT5_ACCOUNT = 'SWAP_FREE_MT5_ACCOUNT';
export const SWAP_MT5_ACCOUNT = 'SWAP_MT5_ACCOUNT';
export const LINK_CAMPAIGN_ACCOUNT = 'LINK_CAMPAIGN_ACCOUNT';
export const CHANGE_MT5_ACCOUNT_GROUP = 'CHANGE_MT5_ACCOUNT_GROUP';
export const ACCOUNT_MT5_OPEN_POSITIONS = 'ACCOUNT_MT5_OPEN_POSITIONS';
export const ACCOUNT_MT5_OPEN_ORDERS = 'ACCOUNT_MT5_OPEN_ORDERS';
export const ACCOUNT_MT5_CLOSE_POSITIONS = 'ACCOUNT_MT5_CLOSE_POSITIONS';
export const RESET_POSITIONS = 'RESET_POSITIONS';
export const LINK_STRUCTURE = 'LINK_STRUCTURE';
export const UNLINK_STRUCTURE = 'UNLINK_STRUCTURE';

/*
 * Clinet Transaction Actions
 */
export const FETCH_CLIENT_TRANSACTIONS = 'FETCH_CLIENT_TRANSACTIONS';
export const UPDATE_CLIENT_TRANSACTION = 'UPDATE_CLIENT_TRANSACTION';
export const ADD_CLIENT_TRANSACTION = 'ADD_CLIENT_TRANSACTION';

/*
 *  Transaction Actions
 */
export const FETCH_DEPOSIT_TRANSACTIONS = 'FETCH_DEPOSIT_TRANSACTIONS';
export const UPDATE_DEPOSIT_TRANSACTION = 'UPDATE_DEPOSIT_TRANSACTION';
export const ADD_DEPOSIT_TRANSACTION = 'ADD_DEPOSIT_TRANSACTION';
export const RESET_DEPOSIT_TRASACTION_REDUCER = 'RESET_DEPOSIT_TRASACTION_REDUCER';

export const FETCH_CREDIT_TRANSACTIONS = 'FETCH_CREDIT_TRANSACTIONS';
export const UPDATE_CREDIT_TRANSACTION = 'UPDATE_CREDIT_TRANSACTION';
export const ADD_CREDIT_TRANSACTION = 'ADD_CREDIT_TRANSACTION';
export const RESET_CREDIT_REDUCER = 'RESET_CREDIT_REDUCER';

export const FETCH_WITHDRAW_TRANSACTIONS = 'FETCH_WITHDRAW_TRANSACTIONS';
export const UPDATE_WITHDRAW_TRANSACTION = 'UPDATE_WITHDRAW_TRANSACTION';
export const ADD_WITHDRAW_TRANSACTION = 'ADD_WITHDRAW_TRANSACTION';
export const RESET_WITHDRAW_REDUCER = 'RESET_WITHDRAW_REDUCER';

export const FETCH_INTERNAL_TRANSFER_TRANSACTIONS =
  'FETCH_INTERNAL_TRANSFER_TRANSACTIONS';
export const UPDATE_INTERNAL_TRANSFER_TRANSACTION =
  'UPDATE_INTERNAL_TRANSFER_TRANSACTION';
export const ADD_INTERNAL_TRANSFER_TRANSACTION =
  'ADD_INTERNAL_TRANSFER_TRANSACTION';
export const RESET_INTERNAL_TRANSFER_REDUCER = 'RESET_INTERNAL_TRANSFER_REDUCER';

export const FETCH_WIRE_TRANSFER_TRANSACTIONS =
  'FETCH_WIRE_TRANSFER_TRANSACTIONS';
export const UPDATE_WIRE_TRANSFER_TRANSACTION =
  'UPDATE_WIRE_TRANSFER_TRANSACTION';
export const ADD_WIRE_TRANSFER_TRANSACTION = 'ADD_WIRE_TRANSFER_TRANSACTION';

/*
 *  Request Actions
 */
export const FETCH_REQUESTS = 'FETCH_REQUESTS';
export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const UPDATE_NEW_ACCOUNT_REQUEST = 'UPDATE_NEW_ACCOUNT_REQUEST';
export const UPDATE_CHANGE_LEVERAGE_REQUEST = 'UPDATE_CHANGE_LEVERAGE_REQUEST';
export const FETCH_PROMOTION_REQUESTS = 'FETCH_PROMOTION_REQUESTS';
export const UPDATE_PROMOTION_REQUEST = 'UPDATE_PROMOTION_REQUEST';
export const RESET_CHANGE_LEVERAGE_REQUEST_REDUCER = 'RESET_CHANGE_LEVERAGE_REQUEST_REDUCER';
export const RESET_ACCOUNT_REQUEST_REDUCER = 'RESET_ACCOUNT_REQUEST_REDUCER';
export const RESET_IB_REQUEST_REDUCER = 'RESET_IB_REQUEST_REDUCER';
export const RESET_PROMOTION_REQUEST_REDUCER = 'RESET_PROMOTION_REQUEST_REDUCER';
export const FETCH_GENERAL_REQUESTS = 'FETCH_GENERAL_REQUESTS';
export const UPDATE_GENERAL_REQUEST = 'UPDATE_GENERAL_REQUEST';
export const RESET_GENERAL_REQUEST_REDUCER = 'RESET_GENERAL_REQUEST_REDUCER';

export const ADD_CLIENT = 'ADD_CLIENT';
export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const FETCH_ALL_CUSTOMERS = 'FETCH_ALL_CUSTOMERS';
export const GET_ACCOUNT_CLIENT = 'GET_ACCOUNT_CLIENT';
export const GET_CLIENT_LIVE_ACCOUNT = 'GET_CLIENT_LIVE_ACCOUNT';
export const GET_CLIENT_JOURNEYS = 'GET_CLIENT_JOURNEYS';
export const GET_CLIENT_IB_JOURNEYS = 'GET_CLIENT_IB_JOURNEYS';
export const GET_REQUESTS_BY_CLIENT = 'GET_REQUESTS_BY_CLIENT';

export const GET_ALL_CALENDAR = 'GET_ALL_CALENDAR';
export const GET_CLIENT_CALENDAR = 'GET_CLIENT_CALENDAR';
export const GET_CLIENT_NOTES = 'GET_CLIENT_NOTES';
export const GET_RECENT_NOTES = 'GET_RECENT_NOTES';
export const ADD_CLIENT_NOTE = 'ADD_CLIENT_NOTE';
export const CHANGE_CLIENT_NOTE = 'CHANGE_CLIENT_NOTE';

/**
 * Leads Actions
 */
export const LEADS_FETCH = 'LEADS_FETCH';
export const LEAD_ADD = 'LEAD_ADD';
export const LEAD_ASSIGN_SALES = 'LEAD_ASSIGN_SALES';
export const LEADS_SALES_AGENTS = 'LEADS_SALES_AGENTS';
export const RESET_LEADS_REDUCER = 'RESET_LEADS_REDUCER';

/**
 * IB Actions
 */
export const LOAD_STRUCTURES_SUCCESS = 'LOAD_STRUCTURES_SUCCESS';
export const LOAD_STRUCTURES_LOADING = 'LOAD_STRUCTURES_LOADING';
export const LOAD_STRUCTURES_ERROR = 'LOAD_STRUCTURES_ERROR';
export const CREATE_STRUCTURE_SUCCESS = 'CREATE_STRUCTURE_SUCCESS';
export const CREATE_STRUCTURE_ERROR = 'CREATE_STRUCTURE_ERROR';
export const DELETE_STRUCTURE_SUCCESS = 'DELETE_STRUCTURE_SUCCESS';
export const UPDATE_STRUCTURE_SUCCESS = 'UPDATE_STRUCTURE_SUCCESS';
export const UPDATE_STRUCTURE_ERROR = 'UPDATE_STRUCTURE_ERROR';
export const GET_CUSTOMER_STRUCTURE_SUCCESS = 'GET_CUSTOMER_STRUCTURE_SUCCESS';
export const GET_CUSTOMER_STRUCTURE_FAILURE = 'GET_CUSTOMER_STRUCTURE_FAILURE';
export const GET_CUSTOMER_STRUCTURE_LOADING = 'GET_CUSTOMER_STRUCTURE_LOADING';
export const LOAD_IB_PREFILL_SUCCESS = 'LOAD_IB_PREFILL_SUCCESS';
export const LOAD_IB_PREFILL_ERROR = 'LOAD_IB_PREFILL_ERROR';

/**
 * TARGET Actions
 */
export const LOAD_TARGET_SUCCESS = 'LOAD_TARGET_SUCCESS';
export const CREATE_TARGET_SUCCESS = 'CREATE_TARGET_SUCCESS';
export const SALE_TARGET_SUCCESS = 'SALE_TARGET_SUCCESS';
export const SALE_TARGET_ERROR = 'SALE_TARGET_ERROR';
export const LOAD_TARGET_ERROR = 'LOAD_TARGET_ERROR';
export const CREATE_TARGET_ERROR = 'CREATE_TARGET_ERROR';
export const GET_ALL_USER_LOADING = 'GET_ALL_USER_LOADING';
export const GET_ALL_USER = 'GET_ALL_USER';
export const GET_ALL_USER_ERROR = 'GET_ALL_USER_ERROR';
export const LOADING_TARGET = 'LOADING_TARGET';
export const SALE_TARGET_LOADING = 'SALE_TARGET_LOADING';
export const RESET_TARGET_REDUCER = 'RESET_TARGET_REDUCER';

/**
 * REPORTING Actions
 */
export const GET_REPORTING_ALL = 'GET_REPORTING_ALL';
export const GET_REPORTING_LOADING = 'GET_REPORTING_LOADING';
export const GET_REPORTING_ALL_ERROR = 'GET_REPORTING_ALL_ERROR';

/**
 * Client statment
 */

export const FETCH_CLIENT_LIST_START = 'FETCH_CLIENT_LIST_START';
export const FETCH_CLIENT_LIST_END = 'FETCH_CLIENT_LIST_END';

export const FETCH_DEALS_START = 'FETCH_DEALS_START';
export const FETCH_DEALS_END = 'FETCH_DEALS_END';
export const SET_COMMISION = 'SET_COMMISION';
export const SET_REBATE = 'SET_REBATE';

/**
 * Whatsapp Message 
 */
 export const FETCH_WA_SESSION_STATUS = 'FETCH_WA_SESSION_STATUS';
 export const SEND_MESSAGE = 'SEND_MESSAGE';
 export const UPDATE_QR_CODE_STATUS = 'UPDATE_QR_CODE_STATUS'

//Text Message 
 export const SEND_TEXT_MESSAGE = 'SEND_TEXT_MESSAGE';

 //Campaign Email Templates

 export const FETCH_CAMPAIGN_EMAIL_TEMPLATES = 'FETCH_CAMPAIGN_EMAIL_TEMPLATES';
 export const FETCH_CAMPAIGN_EMAIL_TEMPLATE_DETAIL = 'FETCH_CAMPAIGN_EMAIL_TEMPLATE_DETAIL';
 
 export const SAVE_CAMPAIGN_EMAIL_TEMPLATES = 'SAVE_CAMPAIGN_EMAIL_TEMPLATES'
 export const UPDATE_CAMPAIGN_EMAIL_TEMPLATE = 'UPDATE_CAMPAIGN_EMAIL_TEMPLATE'
 export const DELETE_CAMPAIGN_EMAIL_TEMPLATE = 'DELETE_CAMPAIGN_EMAIL_TEMPLATE'
 export const SET_CAMPAIGN_TMPL_OBJ = 'SET_CAMPAIGN_TMPL_OBJ'
 export const RESET_CAMPAIGN_TMPL_OBJ = 'RESET_CAMPAIGN_TMPL_OBJ'

 //Campaigns

 export const GET_NEW_CAMPAIGN_OBJ = 'GET_NEW_CAMPAIGN_OBJ';

 export const GET_CAMPAIGN_LIST = 'GET_CAMPAIGN_LIST';
 export const SET_CAMPAIGN_OBJ = 'SET_CAMPAIGN_OBJ'
 export const RESET_CAMPAIGN_OBJ = 'RESET_CAMPAIGN_OBJ'
 export const GET_CAMPAIGN_UNSUBSCRIBERS_LIST = 'GET_CAMPAIGN_UNSUBSCRIBERS_LIST';

 // configs

export const GET_CONFIG_START = 'GET_CONFIG_START';
export const GET_CONFIG_DONE = 'GET_CONFIG_DONE';
export const GET_ACCOUNT_TYPE_DONE = 'GET_ACCOUNT_TYPE_DONE';
export const GET_APPLICATION_TYPE_DONE = 'GET_APPLICATION_TYPE_DONE';
export const GET_DOCUMENT_TYPE_DONE = 'GET_DOCUMENT_TYPE_DONE';
export const GET_KYC_TYPE_DONE = 'GET_KYC_TYPE_DONE';
export const GET_COUNTRIES_DONE = 'GET_COUNTRIES_DONE';
export const GET_CURRENCIES_DONE = 'GET_CURRENCIES_DONE';

export const GET_MARKETING_REPORT_ALL = 'GET_MARKETING_REPORT_ALL';
export const GET_MARKETING_REPORT_LOADING = 'GET_MARKETING_REPORT_LOADING';
export const GET_MARKETING_REPORT_ALL_ERROR = 'GET_MARKETING_REPORT_ALL_ERROR';
