import {
  GET_MARKETING_REPORT_ALL,
  GET_MARKETING_REPORT_LOADING,
  GET_MARKETING_REPORT_ALL_ERROR,
} from '../actions/actionTypes';

const initialState = {
  marketingReportingData: {
    docs: null,
    count: 0,
    //page: 1,
    //totalPages: 0
  },
  marketReportLoading: false,
  errorStatus: false,
  error: '',
}

const reportingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MARKETING_REPORT_ALL:
      
      return {
        ...state,
        marketingReportingData: { ...state.reportingData, ...action.payload },
        marketReportLoading: false,
      };
    case GET_MARKETING_REPORT_LOADING:
      
      return {
        ...state,
        marketReportLoading: !state.reportingLoading
      };
    case GET_MARKETING_REPORT_ALL_ERROR:
      
      return {
        ...state,
        marketReportLoading: false,
        errorStatus: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reportingReducer;
