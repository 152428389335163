/**
 * Reduer for dashboard
 */

import {
  DATA_CLIENTS_ERROR,
  DATA_CLIENTS_SUCCESS,
  DATA_IBS_ERROR,
  DATA_IBS_SUCCESS,
  DATA_KYC_PENDING_ERROR,
  DATA_KYC_PENDING_SUCCESS,
  DATA_LEADS_ERROR,
  DATA_LEADS_SUCCESS,
  DATA_REQUESTS_PENDING_ERROR,
  DATA_REQUESTS_PENDING_SUCCESS,
  DATA_TRANSACTIONS_PENDING_ERROR,
  DATA_TRANSACTIONS_PENDING_SUCCESS,
  GET_CONFIG_FAILURE,
  GET_CONFIG_SUCCESS,
  GET_MY_PROFILE,
  GET_MY_PROFILE_SUCCESS,
  GET_RECENT_NOTES,
  RESET_PAGE_DASHBOARD,
  GET_MAP_DATA,
  GET_SALES_STAT_DATA,
  GET_IB_STAT_DATA,
  GET_REBCOMM_STAT_DATA,
  GET_SALES_SUMMARY_DATA,
  GET_SALES_SUMMARY_DATA_LOADING,
} from '../actions/actionTypes';

const initialState = {
  user: {
    firstName: '',
    lastName: '',
    email: '',
    roleId: ''
  },
  role: {
    title: '',
    permissions: []
  },
  token: '',
  numbers: {
    leads: {
      all: 0,
      new: 0,
      unassigned: 0
    },
    clients: {
      all: 0,
      new: 0,
      unassigned: 0
    },
    ibs: {
      all: 0,
      new: 0,
      unassigned: 0
    },
    kycdocument: {
      pending: 0,
      rejected: 0,
      dormant: 0,
      pendingWithoutDeposit: 0,
      pendingApproval: 0,
      yetToUpload: 0
    },
    pendingRequests: {
      additional: { pending: 0, rejected: 0, approved: 0 },
      leverage: { pending: 0, rejected: 0, approved: 0 },
      partnership: { pending: 0, rejected: 0, approved: 0 },
      promotions: { pending: 0, rejected: 0, approved: 0 }
    },
    pendingTransactions: {
      deposit: { pending: 0, rejected: 0, approved: 0 },
      withdrawal: { pending: 0, rejected: 0, approved: 0 },
      credit: { pending: 0, rejected: 0, approved: 0 },
      internalTransfer: { pending: 0, rejected: 0, approved: 0 }
    },
    pendingVerifications: {
      pending: 0,
      rejected: 0,
      dormant: 0,
      pendingWithoutDeposit: 0,
      pendingApproval: 0,
      yetToUpload: 0,
      expiredDocuments: 0,
      approvedKycUnfunded: 0,
      missingKyc: 0,
      noKycFunded: 0,
      noKycUnfunded: 0,
      pendingKyc: 0,
      rejectedKyc: 0
    }
  },
  reminders: {
    count: 0,
    notes: []
  },
  config: {},
  leadsByCountry: [{ code: 'AE', count: 0 }],
  clientsByCountry: [{ code: 'AE', count: 0 }],
  transactionStats: { meta: {}, data: [] },
  ibStats: { meta: {}, data: [] },
  rebCommStats: { meta: {}, data: [] },
  salesSummaryData: {
    individuals: [],
    teams: []
  },
  isError: false,
  isSuccess: false,
  errorMessage: '',
  isLoading: false
};

const resetKeys = {
  numbers: {
    leads: {
      all: 0,
      new: 0,
      unassigned: 0
    },
    clients: {
      all: 0,
      new: 0,
      unassigned: 0
    },
    ibs: {
      all: 0,
      new: 0,
      unassigned: 0
    },
    kycdocument: {
      pending: 0,
      rejected: 0,
      dormant: 0,
      pendingWithoutDeposit: 0,
      pendingApproval: 0,
      yetToUpload: 0
    },
    pendingRequests: {
      additional: { pending: 0, rejected: 0, approved: 0 },
      leverage: { pending: 0, rejected: 0, approved: 0 },
      partnership: { pending: 0, rejected: 0, approved: 0 },
      promotions: { pending: 0, rejected: 0, approved: 0 }
    },
    pendingTransactions: {
      deposit: { pending: 0, rejected: 0, approved: 0 },
      withdrawal: { pending: 0, rejected: 0, approved: 0 },
      credit: { pending: 0, rejected: 0, approved: 0 },
      internalTransfer: { pending: 0, rejected: 0, approved: 0 }
    },
    pendingVerifications: {
      pending: 0,
      rejected: 0,
      dormant: 0,
      pendingWithoutDeposit: 0,
      pendingApproval: 0,
      yetToUpload: 0,
      expiredDocuments: 0
    }
  },
  reminders: {
    count: 0,
    notes: []
  },
  isError: false,
  isSuccess: false,
  errorMessage: '',
  isLoading: false
};


const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_PROFILE:
      return { ...state };
    case GET_MY_PROFILE_SUCCESS:
      return { ...state, ...action.profile };
    case DATA_LEADS_SUCCESS:
    case DATA_CLIENTS_SUCCESS:
    case DATA_IBS_SUCCESS:
    case DATA_REQUESTS_PENDING_SUCCESS:
    case DATA_TRANSACTIONS_PENDING_SUCCESS:
    case DATA_KYC_PENDING_SUCCESS:
      return {
        ...state,
        numbers: {
          ...state.numbers,
          ...action.data
        }
      };
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          ...action.data
        }
      };
    case DATA_CLIENTS_ERROR:
    case DATA_IBS_ERROR:
    case DATA_LEADS_ERROR:
    case DATA_REQUESTS_PENDING_ERROR:
    case DATA_TRANSACTIONS_PENDING_ERROR:
    case GET_CONFIG_FAILURE:
    case DATA_KYC_PENDING_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isError: action.isError
      };
    case GET_RECENT_NOTES:
      return {
        ...state,
        reminders: action.payload
      };
    case RESET_PAGE_DASHBOARD:
      return {
        ...state,
        ...resetKeys
      };
    case GET_MAP_DATA:
      return action.payload.type === 'clients'
        ? {
            ...state,
            clientsByCountry: action.payload.data
          }
        : {
            ...state,
            leadsByCountry: action.payload.data
          };
    case GET_SALES_STAT_DATA:
      return {
        ...state,
        transactionStats: action.payload
      };
    case GET_IB_STAT_DATA:
      return {
        ...state,
        ibStats: action.payload
      };
    case GET_REBCOMM_STAT_DATA:
      return {
        ...state,
        rebCommStats: action.payload
      };
    case GET_SALES_SUMMARY_DATA_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SALES_SUMMARY_DATA:
      return {
        ...state,
        salesSummaryData: action.payload.data,
        isLoading: false,
      };
    default:
      return state;
  }
};

function actionGetMyProfile() {
  return {
    type: GET_MY_PROFILE
  };
}

export default dashboardReducer;
