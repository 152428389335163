import React, { useState, useEffect } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon } from '@material-ui/core';

// import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import { setSidebarToggleMobile } from '../../store/reducers/ThemeOptions';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import SettingsIcon from '@material-ui/icons/Settings';
import Link from '@material-ui/icons/Link';
import {
  gotNewNotification,
  addNotifications
} from '../../store/reducers/notificationReducer';

import { Collapse } from '@material-ui/core';
import clsx from 'clsx';

import SidebarUserbox from '../SidebarUserbox';
import { getPermissionGroup, getPermissionValues } from '../../utils';
import { initSocket, startFunctions } from '../../websockets';


const useStyles = makeStyles((theme) => ({
  liActive: {
  background: "#07091926"
  }
}));
const SidebarMenu = (props) => {
  const classes = useStyles();
  const { sidebarUserbox, permissions } = props;
  const [dashboardOpen, setDashboardOpen] = useState(false);
  let socket = initSocket();

  const group = getPermissionGroup(true, 'sidebar', '');
  const {
    dashboardPermissions,
    clientsPermissions,
    leadsPermissions,
    settingsPermissions,
    transactionsPermissions,
    requestsPermissions,
    reportsPermissions,
    marketingPermissions,
    remindersPermissions
  } = getPermissionValues(permissions, group, [
    'dashboard',
    'clients',
    'leads',
    'settings',
    'transactions',
    'requests',
    'reports',
    'marketing',
    'reminders'
  ]);

  const secondGroup = getPermissionGroup(true, 'requests', 'structures');
  const { viewPermissions } = getPermissionValues(permissions, secondGroup, [
    'view'
  ]);
  useEffect(() => {
    if (props.userId) {
      socket.emit('joinCrm', { userId: props.userId });
      startFunctions(props.gotNewNotification, props.addNotifications);
    }
  }, [props.userId]);

  const [clientsOpen, setClientsOpen] = useState(false);
  const toggleClients = (event) => {
    setClientsOpen(!clientsOpen);
    event.preventDefault();
  };
  const [leadsOpen, setLeadsOpen] = useState(false);
  const toggleLeads = (event) => {
    setLeadsOpen(!leadsOpen);
    event.preventDefault();
  };

  
  const [ibsOpen, setIbsOpen] = useState(false);
  const toggleIbs = (event) => {
    setIbsOpen(!ibsOpen);
    event.preventDefault();
  };

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);
  const toggleDashboard = (event) => {
    setDashboardOpen(!dashboardOpen);
    event.preventDefault();
  };
  const [MarketingOpen, setMarketingOpen] = useState(false);
  const toggleMarketing = (event) => {
    setMarketingOpen(!MarketingOpen);
    event.preventDefault();
  };

  const [requestsOpen, setRequestsOpen] = useState(false);
  const toggleRequests = (event) => {
    setRequestsOpen(!requestsOpen);
    event.preventDefault();
  };
  const [transactionsOpen, setTransactionsOpen] = useState(false);
  const toggleTransactions = (event) => {
    setTransactionsOpen(!transactionsOpen);
    event.preventDefault();
  };

  const [applicationOpen, setApplicationOpen] = useState(false);
  const toggleApplication = (event) => {
    setApplicationOpen(!applicationOpen);
    event.preventDefault();
  };


  const staticVal = {
    allClients:false,
    clientActive:false,
    inactive:false,
    dormant:false,
    allLeads:false,
    hot:false,
    new:false,
    cold:false,
    archived:false,
    ibRejected:false,
    ibPending:false,
    ibApproved:false,
  }
const [clientChilds, setClientChilds] = useState(staticVal);
const childActive = (type)=>{  
  setClientChilds({...staticVal, [type]:true})

}

  return (
    <>
      <PerfectScrollbar>
        {sidebarUserbox && <SidebarUserbox />}
        <div className="sidebar-navigation">
          <ul>
            {dashboardPermissions && (
              <li>
                <NavLink
                  activeClassName="active"
                  onClick={toggleSidebarMobile}
                  className="nav-link-simple"
                  to="/dashboard">
                  <span className="sidebar-icon">
                    <SvgIcon fontSize="small">
                      <path d="M3.55,17.07a3.38,3.38,0,1,0,3.38,3.38A3.38,3.38,0,0,0,3.55,17.07Zm0,5.92a2.54,2.54,0,1,1,2.53-2.54A2.54,2.54,0,0,1,3.55,23Z" />
                      <path d="M12,17.07a3.38,3.38,0,1,0,3.38,3.38A3.39,3.39,0,0,0,12,17.07ZM12,23a2.54,2.54,0,1,1,2.54-2.54A2.54,2.54,0,0,1,12,23Z" />
                      <path d="M20.45,17.07a3.38,3.38,0,1,0,3.38,3.38A3.39,3.39,0,0,0,20.45,17.07Zm0,5.92A2.54,2.54,0,1,1,23,20.45,2.54,2.54,0,0,1,20.45,23Z" />
                      <path d="M3.55,8.62A3.38,3.38,0,1,0,6.93,12,3.38,3.38,0,0,0,3.55,8.62Zm0,5.92A2.54,2.54,0,1,1,6.08,12,2.54,2.54,0,0,1,3.55,14.54Z" />
                      <path d="M12,8.62A3.38,3.38,0,1,0,15.38,12,3.39,3.39,0,0,0,12,8.62Zm0,5.92A2.54,2.54,0,1,1,14.54,12,2.54,2.54,0,0,1,12,14.54Z" />
                      <path d="M20.45,8.62A3.38,3.38,0,1,0,23.83,12,3.39,3.39,0,0,0,20.45,8.62Zm0,5.92A2.54,2.54,0,1,1,23,12,2.54,2.54,0,0,1,20.45,14.54Z" />
                      <path d="M3.55.17A3.38,3.38,0,1,0,6.93,3.55,3.38,3.38,0,0,0,3.55.17Zm0,5.91A2.54,2.54,0,1,1,6.08,3.55,2.54,2.54,0,0,1,3.55,6.08Z" />
                      <path d="M12,.17a3.38,3.38,0,1,0,3.38,3.38A3.38,3.38,0,0,0,12,.17Zm0,5.91a2.54,2.54,0,1,1,2.54-2.53A2.54,2.54,0,0,1,12,6.08Z" />
                      <path d="M20.45,6.93a3.38,3.38,0,1,0-3.38-3.38A3.38,3.38,0,0,0,20.45,6.93Zm0-5.92a2.54,2.54,0,1,1-2.53,2.54A2.54,2.54,0,0,1,20.45,1Z" />
                    </SvgIcon>
                  </span>
                  Dashboard
                </NavLink>
              </li>
            )}
            {clientsPermissions && (
              <li>
                <a
                  href="#/"
                  onClick={toggleClients}
                  className={clsx({ active: clientsOpen })}>
                  <span className="sidebar-icon">
                    <SvgIcon fontSize="small">
                      <path d="M7.67,11.47A1,1,0,0,0,7.15,11l-1.68-.58-.32-.09v-.28a3.22,3.22,0,0,0,.69-1.25h0c.32,0,.62-.31.66-.76A.74.74,0,0,0,6,7.22H6A1.77,1.77,0,0,0,4,5.55a1.76,1.76,0,0,0-2,1.67H2c-.32,0-.54.43-.49.87s.36.78.68.75l.06,0A3.42,3.42,0,0,0,2.89,10v.37l-.28.08-1.72.56A1.09,1.09,0,0,0,.2,12s-.45,2.05,0,2.5c1,1,3.85,1.31,5.86.88a17.7,17.7,0,0,1,.28-2A2.23,2.23,0,0,1,7.67,11.47Z" />
                      <path d="M23.83,12a1.09,1.09,0,0,0-.7-1l-1.68-.58-.32-.09v-.28a3.37,3.37,0,0,0,.7-1.25h0c.32,0,.62-.31.66-.76a.74.74,0,0,0-.5-.87h0a1.76,1.76,0,0,0-2-1.67,1.77,1.77,0,0,0-2,1.67H18c-.32,0-.54.43-.49.87s.36.78.68.75l.06,0A3.44,3.44,0,0,0,18.88,10v.37l-.28.08-1.73.56a1.16,1.16,0,0,0-.43.3l.17.06a2.22,2.22,0,0,1,1.45,1.94,17.28,17.28,0,0,1,.32,2.07c2,.36,4.63.1,5.45-.91C24.22,14,23.83,12,23.83,12Z" />
                      <path d="M17.27,13.44a1.45,1.45,0,0,0-.91-1.28l-.2-.07-2-.7-1.9,3.49-1.89-3.45-2.28.74-.17.07a1.42,1.42,0,0,0-.75,1.2s-.15.78-.25,1.7c-.11,1.1-.15,2.41.25,2.9,1.59,2,8.35,1.79,10.14,0,.44-.43.41-1.72.29-2.82S17.27,13.44,17.27,13.44Z" />
                      <path d="M9.76,8.69h.08c.42,1.35,1.33,2.39,2.39,2.39a1.94,1.94,0,0,0,1.37-.63l.05,0a4.15,4.15,0,0,0,1-1.7h0c.43,0,.83-.41.89-1s-.25-1.12-.67-1.16h0a2.35,2.35,0,0,0-2.61-2.22A2.35,2.35,0,0,0,9.62,6.54H9.51c-.43,0-.72.57-.65,1.17S9.33,8.74,9.76,8.69Z" />
                    </SvgIcon>
                  </span>
                  <span className="sidebar-item-label">Clients</span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRightTwoToneIcon />
                  </span>
                </a>
                <Collapse in={clientsOpen}>
                  <ul>
                    <li className={clientChilds.allClients ? classes.liActive : ""}>
                      <NavLink 
                      onClick={(e)=>{
                      childActive('allClients');
                      toggleSidebarMobile()}}
                       to="/clients/">
                        All Clients
                      </NavLink>
                    </li>
                    <li className={clientChilds.clientActive ? classes.liActive : ""}>
                      <NavLink
                        onClick={(e)=>{
                          childActive('clientActive');
                          toggleSidebarMobile()}}
                        to="/clients/?show=active">
                        Active
                      </NavLink>
                    </li>
                    <li className={clientChilds.inactive ? classes.liActive : ""}>
                      <NavLink
                           onClick={(e)=>{
                            childActive('inactive');
                            toggleSidebarMobile()}}
                        to="/clients/?show=inactive">
                        Inactive
                      </NavLink>
                    </li>
                    <li className={clientChilds.dormant ? classes.liActive : ""}>
                      <NavLink
                             onClick={(e)=>{
                              childActive('dormant');
                              toggleSidebarMobile()}}
                        to="/clients/?show=dormant">
                        Dormant
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            )}
            {leadsPermissions && (
              <li>
                <a
                  href="#/"
                  onClick={toggleLeads}
                  className={clsx({ active: leadsOpen })}>
                  <span className="sidebar-icon">
                    <SvgIcon fontSize="small">
                      <path d="M6,23.67a.51.51,0,0,1-.51-.51.5.5,0,0,1,.51-.5H7.28a.27.27,0,0,0,.15-.05c.32-.25,1.06-1.06,1.06-3.21a.25.25,0,0,0-.25-.24H2A1.81,1.81,0,0,1,.22,17.35V1.91A1.81,1.81,0,0,1,2,.11H22a1.81,1.81,0,0,1,1.81,1.8V17.35A1.81,1.81,0,0,1,22,19.16H15.76a.25.25,0,0,0-.25.24c0,2.15.74,3,1,3.21a.27.27,0,0,0,.15.05H18a.5.5,0,0,1,.51.5.51.51,0,0,1-.51.51Zm3.76-4.51a.25.25,0,0,0-.25.24,6.51,6.51,0,0,1-.58,2.9.25.25,0,0,0,0,.24.27.27,0,0,0,.21.12h5.72a.27.27,0,0,0,.21-.12.25.25,0,0,0,0-.24,6.51,6.51,0,0,1-.58-2.9.25.25,0,0,0-.25-.24ZM1.48,14.65a.25.25,0,0,0-.25.25v2.45a.8.8,0,0,0,.8.8H22a.8.8,0,0,0,.8-.8V1.91a.8.8,0,0,0-.8-.79H2a.8.8,0,0,0-.8.79V13.39a.25.25,0,0,0,.25.25h9a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.51Z" />
                      <rect x="11.5" y="15.89" width="1.01" height="1.01" />
                      <path d="M9,9.39A2.76,2.76,0,1,1,9,3.87h6a2.76,2.76,0,0,1,2,.91,2.73,2.73,0,0,1,.55.93.51.51,0,0,1-.31.65l-.16,0a.5.5,0,0,1-.48-.33,1.85,1.85,0,0,0-.35-.59A1.75,1.75,0,0,0,15,4.88H9a1.75,1.75,0,1,0,0,3.5h.76a.5.5,0,0,1,.5.5.51.51,0,0,1-.5.51Z" />
                      <path d="M19,16.15a.47.47,0,0,1-.35-.15L16,13.36a.25.25,0,0,0-.18-.08.25.25,0,0,0-.17.08l-1,1a.55.55,0,0,1-.36.15l-.14,0a.49.49,0,0,1-.35-.37L12.26,7.49A.51.51,0,0,1,12.39,7a.55.55,0,0,1,.36-.15l.12,0,6.61,1.52a.51.51,0,0,1,.24.85l-1,1a.24.24,0,0,0,0,.35l2.65,2.64a.54.54,0,0,1,.14.36.52.52,0,0,1-.14.36l-2,2A.48.48,0,0,1,19,16.15ZM15.8,12a.51.51,0,0,1,.36.15l2.65,2.65a.23.23,0,0,0,.17.07.24.24,0,0,0,.18-.07l1-1a.24.24,0,0,0,.07-.18.22.22,0,0,0-.07-.17l-2.65-2.65a.51.51,0,0,1,0-.71l.57-.57a.26.26,0,0,0,.06-.25.24.24,0,0,0-.18-.17l-4.12-.95h-.06a.25.25,0,0,0-.17.08.24.24,0,0,0-.07.23l1,4.12a.23.23,0,0,0,.17.18h.07a.28.28,0,0,0,.17-.07l.57-.57A.47.47,0,0,1,15.8,12Z" />
                    </SvgIcon>
                  </span>
                  <span className="sidebar-item-label">Leads</span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRightTwoToneIcon />
                  </span>
                </a>
                <Collapse in={leadsOpen}>
                  <ul>
                    <li className={clientChilds.allLeads ? classes.liActive : ""}>
                      <NavLink 
                       onClick={(e)=>{
                        childActive('allLeads');
                        toggleSidebarMobile()}}
                       to="/leads/">
                        All Leads
                      </NavLink>
                    </li>
                    <li className={clientChilds.new ? classes.liActive : ""}>
                      <NavLink
                         onClick={(e)=>{
                          childActive('new');
                          toggleSidebarMobile()}}
                        to="/leads/?show=new">
                        New
                      </NavLink>
                    </li>
                    <li className={clientChilds.hot ? classes.liActive : ""}>
                      <NavLink
                         onClick={(e)=>{
                          childActive('hot');
                          toggleSidebarMobile()}}
                        to="/leads/?show=hot">
                        Hot
                      </NavLink>
                    </li>
                    <li className={clientChilds.cold ? classes.liActive : ""}>
                      <NavLink
                        onClick={(e)=>{
                          childActive('cold');
                          toggleSidebarMobile()}}
                        to="/leads/?show=cold">
                        Cold
                      </NavLink>
                    </li>
                    <li className={clientChilds.archived ? classes.liActive : ""}>
                      <NavLink
                         onClick={(e)=>{
                          childActive('archived');
                          toggleSidebarMobile()}}
                        to="/leads/?show=archived">
                        Archived
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            )}
               {clientsPermissions && (
              <li>
                <a
                  href="#/"
                  onClick={toggleIbs}
                  className={clsx({ active: ibsOpen })}>
                  <span className="sidebar-icon">
                    <SvgIcon fontSize="small">
                      <path d="M7.67,11.47A1,1,0,0,0,7.15,11l-1.68-.58-.32-.09v-.28a3.22,3.22,0,0,0,.69-1.25h0c.32,0,.62-.31.66-.76A.74.74,0,0,0,6,7.22H6A1.77,1.77,0,0,0,4,5.55a1.76,1.76,0,0,0-2,1.67H2c-.32,0-.54.43-.49.87s.36.78.68.75l.06,0A3.42,3.42,0,0,0,2.89,10v.37l-.28.08-1.72.56A1.09,1.09,0,0,0,.2,12s-.45,2.05,0,2.5c1,1,3.85,1.31,5.86.88a17.7,17.7,0,0,1,.28-2A2.23,2.23,0,0,1,7.67,11.47Z" />
                      <path d="M23.83,12a1.09,1.09,0,0,0-.7-1l-1.68-.58-.32-.09v-.28a3.37,3.37,0,0,0,.7-1.25h0c.32,0,.62-.31.66-.76a.74.74,0,0,0-.5-.87h0a1.76,1.76,0,0,0-2-1.67,1.77,1.77,0,0,0-2,1.67H18c-.32,0-.54.43-.49.87s.36.78.68.75l.06,0A3.44,3.44,0,0,0,18.88,10v.37l-.28.08-1.73.56a1.16,1.16,0,0,0-.43.3l.17.06a2.22,2.22,0,0,1,1.45,1.94,17.28,17.28,0,0,1,.32,2.07c2,.36,4.63.1,5.45-.91C24.22,14,23.83,12,23.83,12Z" />
                      <path d="M17.27,13.44a1.45,1.45,0,0,0-.91-1.28l-.2-.07-2-.7-1.9,3.49-1.89-3.45-2.28.74-.17.07a1.42,1.42,0,0,0-.75,1.2s-.15.78-.25,1.7c-.11,1.1-.15,2.41.25,2.9,1.59,2,8.35,1.79,10.14,0,.44-.43.41-1.72.29-2.82S17.27,13.44,17.27,13.44Z" />
                      <path d="M9.76,8.69h.08c.42,1.35,1.33,2.39,2.39,2.39a1.94,1.94,0,0,0,1.37-.63l.05,0a4.15,4.15,0,0,0,1-1.7h0c.43,0,.83-.41.89-1s-.25-1.12-.67-1.16h0a2.35,2.35,0,0,0-2.61-2.22A2.35,2.35,0,0,0,9.62,6.54H9.51c-.43,0-.72.57-.65,1.17S9.33,8.74,9.76,8.69Z" />
                    </SvgIcon>
                  </span>
                  <span className="sidebar-item-label">Ibs</span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRightTwoToneIcon />
                  </span>
                </a>
                <Collapse in={ibsOpen}>
                  <ul>
                    <li className={clientChilds.ibApporved ? classes.liActive : ""}>
                      <NavLink
                        onClick={(e)=>{
                          childActive('ibApporved');
                          toggleSidebarMobile()}}
                        to="/ibs/?show=approved">
                        Approved
                      </NavLink>
                    </li>
                    <li className={clientChilds.ibPending ? classes.liActive : ""}>
                      <NavLink
                                 onClick={(e)=>{
                                  childActive('ibPending');
                                  toggleSidebarMobile()}}
                        to="/requests/ib?status=Pending">
                        Pending
                      </NavLink>
                    </li>
                    <li className={clientChilds.ibRejected ? classes.liActive : ""}>
                      <NavLink
                                 onClick={(e)=>{
                                  childActive('ibRejected');
                                  toggleSidebarMobile()}}
                        to="/requests/ib?status=Rejected">
                        Rejected
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            )}
            {transactionsPermissions && (
              <li>
                <a
                  href="#/"
                  onClick={toggleTransactions}
                  className={clsx({ active: transactionsOpen })}>
                  <span className="sidebar-icon">
                    <SvgIcon fontSize="small">
                      <polygon points="10.31 17.82 10.2 18.46 13.48 18.46 13.37 17.82 13.35 17.73 10.33 17.73 10.31 17.82" />
                      <path d="M22,15.85v-14H2.19v14L.11,20.08v2.08h24V20.08ZM3.78,3.46H20.41V14.35H3.78Zm16.63,13.8h-3l-.21-.6h2.89Zm-7.06.47,0,.09.11.64H10.2l.11-.64,0-.09Zm-2.94-.47.11-.6h2.64l.11.6Zm-6.28-.6H6.76l-.22.6H3.78Zm1.53,3H2.37l.45-.76H5.93ZM3.09,18.46l.42-.73H6.37l-.27.73ZM9.5,19.69H6.16l.28-.76H9.63Zm.22-1.23H6.61l.24-.65,0-.08h3Zm-2.67-1.2.22-.6H10l-.1.6ZM10,19.69l.13-.76h3.44l.14.76Zm3.66-3h3l.21.6H13.76Zm.2,1.16v-.09H17l.27.73H14Zm.33,1.87-.13-.76h3.43l.28.76Zm3.38-2h3.12l.42.73H17.82Zm.71,2L18,18.93h3.39l.44.76Z" />
                      <polygon points="17.2 6.85 13.85 11.1 10.3 6.99 9.85 6.47 9.4 7.07 5.83 11.83 7.22 12.78 9.99 8.31 13.62 11.84 13.92 12.12 14.13 11.8 17.32 6.94 18.25 7.59 18.47 5.18 16.26 6.19 17.2 6.85" />
                    </SvgIcon>
                  </span>
                  <span className="sidebar-item-label">Transactions</span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRightTwoToneIcon />
                  </span>
                </a>
                <Collapse in={transactionsOpen}>
                  <ul>
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/transaction/deposit">
                        Deposit
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/transaction/withdraw">
                        Withdrawal
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/transaction/internal-transfer">
                        Internal Transfer
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/transaction/credit">
                        Credit
                      </NavLink>
                    </li>
                    {/* <li>
                    <NavLink onClick={toggleSidebarMobile} to="/transaction/wire-transfer">
                      Wire Transfer
                    </NavLink>
                  </li> */}
                  </ul>
                </Collapse>
              </li>
            )}
            {requestsPermissions && (
              <li>
                <a
                  href="#/"
                  onClick={toggleRequests}
                  className={clsx({ active: requestsOpen })}>
                  <span className="sidebar-icon">
                    <SvgIcon fontSize="small">
                      <path d="M16.54.1H2.21V23.9H21.79V5.36Zm.38,4.38V2.57l.11.11.23.24.16.15L18.8,4.46l.78.77H16.92ZM20.5,22.6H3.5V1.4H15.62V5.88a.64.64,0,0,0,.47.62l.18,0H20.5Z" />
                      <rect x="4.97" y="19.2" width="13.87" height="1.56" />
                      <rect x="4.97" y="15.99" width="13.87" height="1.56" />
                      <rect x="4.97" y="12.79" width="13.87" height="1.56" />
                      <rect x="12.66" y="9.58" width="6.19" height="1.56" />
                      <path d="M18.31,7.3h-2A1.42,1.42,0,0,1,15.46,7a1.44,1.44,0,0,1-.28-.27A1.27,1.27,0,0,1,15,6.5H12.66V8.06h6.19V7.3h-.54Z" />
                      <polygon points="14.85 4.21 14.85 3.94 14.85 3.46 14.85 3.19 14.85 2.92 12.66 2.92 12.66 4.48 14.85 4.48 14.85 4.21" />
                      <path d="M10.93,10.46c.43-.43,0-2.46,0-2.46a.79.79,0,0,0-.49-.69L9.46,7A1.73,1.73,0,0,1,7,7L6,7.32a.75.75,0,0,0-.5.68s-.39,2,0,2.46C6.35,11.52,10,11.43,10.93,10.46Z" />
                      <path d="M6.9,5.46H7c.22.72.71,1.28,1.28,1.28s1-.56,1.28-1.28h0c.23,0,.44-.22.47-.54a.53.53,0,0,0-.36-.62h0a1.25,1.25,0,0,0-1.4-1.18A1.25,1.25,0,0,0,6.83,4.3H6.77a.52.52,0,0,0-.35.63C6.46,5.25,6.67,5.48,6.9,5.46Z" />
                    </SvgIcon>
                  </span>
                  <span className="sidebar-item-label">Requests</span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRightTwoToneIcon />
                  </span>
                </a>
                <Collapse in={requestsOpen}>
                  <ul>
                    {/* <li>
                      <NavLink onClick={toggleSidebarMobile} to="/requests/ib">
                        IB Request
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/requests/new-account">
                        New Account Request
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/requests/change-leverage">
                        Change Leverage
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/requests/promotion-requests">
                        Promotion Requests
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/requests/general-requests">
                        General Requests
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            )}
            {remindersPermissions && (
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link-simple"
                  to="/reminders">
                  <span className="sidebar-icon">
                    <SvgIcon fontSize="small">
                      <rect
                        className="cls-1"
                        x="3.57"
                        y="5.77"
                        width="4.08"
                        height="2.77"
                      />
                      <rect
                        className="cls-1"
                        x="8.85"
                        y="5.77"
                        width="4.08"
                        height="2.77"
                      />
                      <rect
                        className="cls-1"
                        x="14.33"
                        y="5.77"
                        width="4.08"
                        height="2.77"
                      />
                      <rect
                        className="cls-1"
                        x="3.57"
                        y="9.44"
                        width="4.08"
                        height="2.77"
                      />
                      <rect
                        className="cls-1"
                        x="3.57"
                        y="13.09"
                        width="4.08"
                        height="2.77"
                      />
                      <path
                        className="cls-1"
                        d="M21.59,11.78V2.39a.76.76,0,0,0-.75-.75H17.45V1.16a.75.75,0,0,0-1.5,0v.48H6V1.16a.75.75,0,0,0-1.5,0v.48H1.14a.76.76,0,0,0-.75.75v16a.75.75,0,0,0,.75.74h9.7a6.69,6.69,0,1,0,10.75-7.33ZM8.85,13.09v2.77h1.6a6,6,0,0,0-.06.85,6.84,6.84,0,0,0,.07.91H1.89V3.14H4.53v.57a.75.75,0,0,0,1.5,0V3.14H16v.57a.75.75,0,1,0,1.5,0V3.14h2.64v7.6a6.32,6.32,0,0,0-1.68-.59V9.44H14.33v1.17a6.88,6.88,0,0,0-1.4.85v-2H8.85v2.78h3.28a6.29,6.29,0,0,0-.67.87Zm8.23,9.1a5.48,5.48,0,0,1-4.92-3.08,5,5,0,0,1-.48-1.49,5.5,5.5,0,0,1-.09-.91,4.8,4.8,0,0,1,.08-.85,5.5,5.5,0,0,1,1.26-2.73A5.38,5.38,0,0,1,14.33,12a5.47,5.47,0,0,1,2.75-.75,5.33,5.33,0,0,1,1.33.17,5.19,5.19,0,0,1,1.68.74,5.48,5.48,0,0,1-3,10.06Z"
                      />
                      <polygon
                        className="cls-1"
                        points="17.55 12.22 17.55 11.9 16.63 11.9 16.63 12.22 16.62 13.37 17.55 13.37 17.55 12.22"
                      />
                      <polygon
                        className="cls-1"
                        points="15.37 13.61 15.63 13.46 15.59 13.39 15.04 12.42 14.4 12.78 14.99 13.83 15.37 13.61"
                      />
                      <polygon
                        className="cls-1"
                        points="13.16 14.89 13.56 15.13 13.7 15.21 13.81 15.28 13.84 15.24 14.19 14.64 13.84 14.43 13.7 14.35 13.56 14.27 13.16 14.04 13.16 14.03 12.93 14.42 12.79 14.67 12.93 14.76 13.16 14.89"
                      />
                      <polygon
                        className="cls-1"
                        points="13.56 17.17 13.7 17.17 13.73 17.17 13.73 16.24 13.7 16.24 13.56 16.24 13.16 16.25 12.77 16.25 12.63 16.25 12.49 16.25 12.28 16.25 12.28 17.17 12.49 17.17 12.63 17.17 12.77 17.17 13.16 17.17 13.56 17.17"
                      />
                      <polygon
                        className="cls-1"
                        points="13.83 18.16 13.7 18.24 13.56 18.32 13.16 18.55 12.79 18.77 12.99 19.11 13.16 19.41 13.16 19.41 13.56 19.18 13.66 19.11 13.7 19.09 13.84 19.01 14.2 18.8 13.84 18.19 13.83 18.16"
                      />
                      <polygon
                        className="cls-1"
                        points="14.58 20.28 14.5 20.42 14.42 20.56 14.38 20.63 14.93 20.95 15.02 21 15.05 20.95 15.27 20.56 15.36 20.42 15.44 20.28 15.62 19.96 14.98 19.59 14.58 20.28"
                      />
                      <polygon
                        className="cls-1"
                        points="16.62 20.28 16.62 20.42 16.62 20.56 16.62 20.95 16.61 21.35 16.61 21.49 16.61 21.51 17.54 21.52 17.54 21.49 17.54 21.35 17.54 20.95 17.55 20.56 17.55 20.42 17.55 20.28 17.55 20.1 16.63 20.09 16.62 20.28"
                      />
                      <polygon
                        className="cls-1"
                        points="19.65 20.42 19.57 20.28 19.18 19.58 18.53 19.95 18.72 20.28 18.8 20.42 18.88 20.56 19.11 20.95 19.13 21 19.21 20.95 19.77 20.63 19.74 20.56 19.65 20.42"
                      />
                      <polygon
                        className="cls-1"
                        points="20.32 18.18 20.21 18.37 19.95 18.82 20.46 19.11 21 19.42 21.24 19 21.37 18.78 20.65 18.37 20.32 18.18"
                      />
                      <polygon
                        className="cls-1"
                        points="21.59 16.25 20.42 16.24 20.42 16.74 20.42 17.02 20.42 17.16 21.59 17.17 21.88 17.18 21.88 17.02 21.88 16.74 21.88 16.25 21.59 16.25"
                      />
                      <polygon
                        className="cls-1"
                        points="20.09 14.85 20.19 15.02 20.33 15.27 20.75 15.02 21.23 14.74 21.38 14.66 21.26 14.46 21 14.02 20.24 14.46 20.09 14.55 19.96 14.62 20.03 14.74 20.09 14.85"
                      />
                      <polygon
                        className="cls-1"
                        points="19.16 13.83 19.25 13.67 19.41 13.39 19.76 12.78 19.12 12.41 18.56 13.39 18.52 13.46 18.9 13.67 19.16 13.83"
                      />
                      <path
                        className="cls-1"
                        d="M17.51,15.89V14a.13.13,0,0,0,0-.06.43.43,0,0,0-.15-.28.44.44,0,0,0-.53,0,.43.43,0,0,0-.15.28.13.13,0,0,0,0,.06v1.87a.94.94,0,0,0-.51.83v0a1.4,1.4,0,0,0,.06.28.82.82,0,0,0,.09.19l-.05.09-.19.32-.1.19-.17.28-.16.28-.05.1a.42.42,0,0,0,.15.58.54.54,0,0,0,.21.05.41.41,0,0,0,.37-.21l.3-.52.16-.28.17-.28.1-.18h.18a.93.93,0,0,0,.57-.32A1.12,1.12,0,0,0,18,17a.87.87,0,0,0,.05-.28v0A.93.93,0,0,0,17.51,15.89Z"
                      />
                    </SvgIcon>
                  </span>
                  Reminders
                </NavLink>
              </li>
            )}
            {reportsPermissions && (
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link-simple"
                  to="/reporting">
                  <span className="sidebar-icon">
                    <SvgIcon fontSize="small">
                      <path d="M7.67,11.47A1,1,0,0,0,7.15,11l-1.68-.58-.32-.09v-.28a3.22,3.22,0,0,0,.69-1.25h0c.32,0,.62-.31.66-.76A.74.74,0,0,0,6,7.22H6A1.77,1.77,0,0,0,4,5.55a1.76,1.76,0,0,0-2,1.67H2c-.32,0-.54.43-.49.87s.36.78.68.75l.06,0A3.42,3.42,0,0,0,2.89,10v.37l-.28.08-1.72.56A1.09,1.09,0,0,0,.2,12s-.45,2.05,0,2.5c1,1,3.85,1.31,5.86.88a17.7,17.7,0,0,1,.28-2A2.23,2.23,0,0,1,7.67,11.47Z" />
                      <path d="M23.83,12a1.09,1.09,0,0,0-.7-1l-1.68-.58-.32-.09v-.28a3.37,3.37,0,0,0,.7-1.25h0c.32,0,.62-.31.66-.76a.74.74,0,0,0-.5-.87h0a1.76,1.76,0,0,0-2-1.67,1.77,1.77,0,0,0-2,1.67H18c-.32,0-.54.43-.49.87s.36.78.68.75l.06,0A3.44,3.44,0,0,0,18.88,10v.37l-.28.08-1.73.56a1.16,1.16,0,0,0-.43.3l.17.06a2.22,2.22,0,0,1,1.45,1.94,17.28,17.28,0,0,1,.32,2.07c2,.36,4.63.1,5.45-.91C24.22,14,23.83,12,23.83,12Z" />
                      <path d="M17.27,13.44a1.45,1.45,0,0,0-.91-1.28l-.2-.07-2-.7-1.9,3.49-1.89-3.45-2.28.74-.17.07a1.42,1.42,0,0,0-.75,1.2s-.15.78-.25,1.7c-.11,1.1-.15,2.41.25,2.9,1.59,2,8.35,1.79,10.14,0,.44-.43.41-1.72.29-2.82S17.27,13.44,17.27,13.44Z" />
                      <path d="M9.76,8.69h.08c.42,1.35,1.33,2.39,2.39,2.39a1.94,1.94,0,0,0,1.37-.63l.05,0a4.15,4.15,0,0,0,1-1.7h0c.43,0,.83-.41.89-1s-.25-1.12-.67-1.16h0a2.35,2.35,0,0,0-2.61-2.22A2.35,2.35,0,0,0,9.62,6.54H9.51c-.43,0-.72.57-.65,1.17S9.33,8.74,9.76,8.69Z" />
                    </SvgIcon>
                  </span>
                  Reports
                </NavLink>
              </li>
            )}
            {marketingPermissions && (
              <li>
                <a
                  href="#/"
                  onClick={toggleMarketing}
                  className={clsx({ active: MarketingOpen })}>
                  <span className="sidebar-icon">
                    <Link />
                  </span>
                  <span className="sidebar-item-label">Marketing</span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRightTwoToneIcon />
                  </span>
                </a>
                <Collapse in={MarketingOpen}>
                  <ul>
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/marketing/link">
                        Link
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/campaign-templates">
                        Campaign Templates
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/campaign">
                        Campaigns
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/campaign-unsubscribers">
                        Campaign Unsubscribers
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/marketing-report">
                        Marketing Report
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            )}

            {settingsPermissions && (
              <li>
                <a
                  href="#/"
                  onClick={toggleDashboard}
                  className={clsx({ active: dashboardOpen })}>
                  <span className="sidebar-icon">
                    <SettingsIcon />
                  </span>
                  <span className="sidebar-item-label">Settings</span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRightTwoToneIcon />
                  </span>
                </a>
                <Collapse in={dashboardOpen}>
                  <ul>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/roles">
                        Role
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/users">
                        Users
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/user-logs">
                        User Logs
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/teams">
                        Teams
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/campaigns">
                        Campaigns
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/targets">
                        Targets
                      </NavLink>
                    </li>

                    {/* <li>
                    <NavLink onClick={toggleSidebarMobile} to="/client-requests">
                      Client Request
                    </NavLink>
                  </li> */}
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/email-templates">
                        Email Templates
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            )}
            {/* <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/transactions">
                <span className="sidebar-icon">
                  <SvgIcon fontSize="small">
                    <polygon points="10.31 17.82 10.2 18.46 13.48 18.46 13.37 17.82 13.35 17.73 10.33 17.73 10.31 17.82"/>
                    <path d="M22,15.85v-14H2.19v14L.11,20.08v2.08h24V20.08ZM3.78,3.46H20.41V14.35H3.78Zm16.63,13.8h-3l-.21-.6h2.89Zm-7.06.47,0,.09.11.64H10.2l.11-.64,0-.09Zm-2.94-.47.11-.6h2.64l.11.6Zm-6.28-.6H6.76l-.22.6H3.78Zm1.53,3H2.37l.45-.76H5.93ZM3.09,18.46l.42-.73H6.37l-.27.73ZM9.5,19.69H6.16l.28-.76H9.63Zm.22-1.23H6.61l.24-.65,0-.08h3Zm-2.67-1.2.22-.6H10l-.1.6ZM10,19.69l.13-.76h3.44l.14.76Zm3.66-3h3l.21.6H13.76Zm.2,1.16v-.09H17l.27.73H14Zm.33,1.87-.13-.76h3.43l.28.76Zm3.38-2h3.12l.42.73H17.82Zm.71,2L18,18.93h3.39l.44.76Z"/>
                    <polygon points="17.2 6.85 13.85 11.1 10.3 6.99 9.85 6.47 9.4 7.07 5.83 11.83 7.22 12.78 9.99 8.31 13.62 11.84 13.92 12.12 14.13 11.8 17.32 6.94 18.25 7.59 18.47 5.18 16.26 6.19 17.2 6.85"/>
                  </SvgIcon>
                </span>
                Transactions
              </NavLink>
            </li> */}

            {/* <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/requests">
                <span className="sidebar-icon">
                  <SvgIcon fontSize="small">
                    <path d="M16.54.1H2.21V23.9H21.79V5.36Zm.38,4.38V2.57l.11.11.23.24.16.15L18.8,4.46l.78.77H16.92ZM20.5,22.6H3.5V1.4H15.62V5.88a.64.64,0,0,0,.47.62l.18,0H20.5Z" />
                    <rect x="4.97" y="19.2" width="13.87" height="1.56" />
                    <rect x="4.97" y="15.99" width="13.87" height="1.56" />
                    <rect x="4.97" y="12.79" width="13.87" height="1.56" />
                    <rect x="12.66" y="9.58" width="6.19" height="1.56" />
                    <path d="M18.31,7.3h-2A1.42,1.42,0,0,1,15.46,7a1.44,1.44,0,0,1-.28-.27A1.27,1.27,0,0,1,15,6.5H12.66V8.06h6.19V7.3h-.54Z" />
                    <polygon points="14.85 4.21 14.85 3.94 14.85 3.46 14.85 3.19 14.85 2.92 12.66 2.92 12.66 4.48 14.85 4.48 14.85 4.21" />
                    <path d="M10.93,10.46c.43-.43,0-2.46,0-2.46a.79.79,0,0,0-.49-.69L9.46,7A1.73,1.73,0,0,1,7,7L6,7.32a.75.75,0,0,0-.5.68s-.39,2,0,2.46C6.35,11.52,10,11.43,10.93,10.46Z" />
                    <path d="M6.9,5.46H7c.22.72.71,1.28,1.28,1.28s1-.56,1.28-1.28h0c.23,0,.44-.22.47-.54a.53.53,0,0,0-.36-.62h0a1.25,1.25,0,0,0-1.4-1.18A1.25,1.25,0,0,0,6.83,4.3H6.77a.52.52,0,0,0-.35.63C6.46,5.25,6.67,5.48,6.9,5.46Z" />
                  </SvgIcon>
                </span>
                Requests
              </NavLink>
            </li> */}
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  permissions: state.dashboardReducer.role.permissions,
  userId: state.dashboardReducer.user._id
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
  gotNewNotification: (data) => dispatch(gotNewNotification(data)),
  addNotifications: (data) => dispatch(addNotifications(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
